@import "../variables";

/* stylelint-disable */
@mixin btn-primary() {
    background-color: $border-button-default;
    border: 0;
    box-shadow: none;
    color: $white;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;

    &:hover {
        background-color: $border-button-hover;
    }

    &:focus,
    &:active:focus,
    &:not(:disabled):not(.disabled):active:focus {
        box-shadow: none;
    }

    &:disabled,
    &.disabled {
        background: $surface-button-text;
        border: 0;
        opacity: 1;
    }
}

@mixin btn-secondary() {
    background-color: transparent;
    color: $black;
    border-radius: 0;
    text-decoration: none;
    border: 2px solid $black;

    &:hover {
        color: $border-button-hover;
        border-color: $border-button-hover;
        background-color: transparent;
    }

    &:focus,
    &:active:focus,
    &:not(:disabled):not(.disabled):active:focus {
        box-shadow: none;
    }
}

@mixin btn-tertiary() {
    text-transform: uppercase;
    border-bottom: 1px solid $border-button-default !important;
    color: $text-color-1;
    font-weight: 700;
    font-size: rem-calc(14);
    padding: rem-calc(10 0);
    max-width: fit-content;
    text-decoration: none;

    &:hover {
        border-bottom: 1px solid $blue !important;
        color: $blue;
    }
}

@mixin custom-radiobox() {
    .custom-radio {
        input[type="radio"] {
            appearance: none;
        }

        .custom-label {
            cursor: pointer;
            margin-left: rem-calc(8);
            line-height: 1.6;

            &::before {
                background-color: $white;
                border:1px solid $grey3;
                border-radius: 100%;
                box-shadow: rem-calc(0 4 4 0) #0000000a inset;
                height: rem-calc(24);
                width: rem-calc(24);
                content: "";
                position: absolute;
                top: rem-calc(2);
                left: 0;
            }
        }

        input[type="radio"]:checked ~ .custom-label {
            &::before {
                border-color: $blue;
                box-shadow: none;
            }
        }

        input[type="radio"]:checked ~ .custom-label {
            &::after {
                background-color: $blue;
                border: 0;
                border-radius: 100%;
                height: rem-calc(16);
                width: rem-calc(16);
                content: "";
                position: absolute;
                top: rem-calc(6);
                left: rem-calc(4);
            }
        }
    }
}

@mixin select-box() {
    border-radius: 0;
    min-height: rem-calc(48);
    appearance: none;
    background: url("../../images/icons/cheron-down.svg") no-repeat 98% center;
    cursor: pointer;

    &:focus {
        box-shadow: none;
    }
}


@mixin row-no-gutters() {
    margin-left: rem-calc(-8);
    margin-right: rem-calc(-8);

    @include media-breakpoint-up(lg) {
        margin-left: rem-calc(-12);
        margin-right: rem-calc(-12);
    }
}

@mixin addrees-autocomplete() {
    background: $white;
    left: 0;
    list-style: none;
    margin: rem-calc(-15 0 0);
    max-height: rem-calc(300);
    overflow: auto;
    padding: rem-calc(2 0);
    position: absolute;
    width: 100%;
    z-index: 1;

    &.result-open {
        border: 1px solid $grey3;
    }

    .pac-item {
        cursor: pointer;
        font-size: rem-calc(14);
        padding: rem-calc(6 16);
    }

    .pac-item:hover {
        background-color: $surface-general-secondary;
    }
}