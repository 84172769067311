@import "~base/variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "bootstrap/scss/variables";
@import "~base/storeLocator/storeLocator";

.in-store-inventory-dialog {
    max-width: 56.25em;

    .mt-auto {
        @include media-breakpoint-up(sm) {
            margin-top: -2rem !important;
        }

        @include media-breakpoint-down(xs) {
            margin-top: 1.15em !important;
        }
    }
}

.btn-storelocator-search {
    background-color: $light-gray;
    border-color: $primary;
    border-radius: 0;
    color: $primary;
    margin-bottom: 1rem;

    &:hover {
        background-color: $primary;
        color: $light-gray;
    }
}

.store-name {
    font-weight: 600;
}

.btn-get-in-store-inventory {
    .btn-storelocator-search {
        display: block;
        margin-bottom: 0.625rem;
        width: 100%;

        @include media-breakpoint-up(sm) {
            margin-bottom: 0;
            margin-top: 2rem;
        }
    }
}

.selected-store-with-inventory {
    .change-store {
        margin: 0 1em 1em;
        width: auto;
    }
}

.display-none {
    display: none;
}