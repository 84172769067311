.container {
    padding-left: 16px;
    padding-right: 16px;

    @include media-breakpoint-up(lg) {
        padding-left: 32px;
        padding-right: 32px;
    }
}

.pull-right { float: right; }
.pull-left { float: left; }


.form-control {
    border-color: $horizontal-rule-grey;
    border-radius: 0;
    color: $black;
    font-size: rem-calc(14);
    font-weight: 400;
    height: rem-calc(48);
    letter-spacing: (0.5);
    padding: rem-calc(8 16);

    &.mandatory-field {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23f64218' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f64218' stroke='none'/%3e%3c/svg%3e");
        background-position: right calc(0.375em + 0.1875rem) center;
        background-repeat: no-repeat;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
        border-color: #f64218;
        padding-right: calc(1.5em + 0.75rem) !important;
    }
    // stylelint-disable-next-line
    &.addressSelector {
        padding: rem-calc(8 32 8 8);
    }
}

.row {
    margin-left: auto;
    margin-right: auto;

    .row-no-gutters {
        margin-left: rem-calc(-8);
        margin-right: rem-calc(-8);

        @include media-breakpoint-up(lg) {
            margin-left: rem-calc(-12);
            margin-right: rem-calc(-12);
        }
    }
}

.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
    padding-left: 8px;
    padding-right: 8px;

    @include media-breakpoint-up(lg) {
        padding-left: 12px;
        padding-right: 12px;
    }
}

.pb-20 {
    padding-bottom: rem-calc(20);
}

.breadcrumb {
    background-color: transparent;
    display: flex;
    flex-wrap: wrap;
    font-size: rem-calc(13);
    list-style: none;
    margin-bottom: 0;
    padding: $breadcrumb-padding-y 0;
}

.breadcrumb-item {
    a {
        color: $text-secondary;
        text-decoration: none;
    }

    + .breadcrumb-item {
        padding-left: $breadcrumb-item-padding;

        &::before {
            content: escape-svg($breadcrumb-divider);
            float: left; // Suppress inline spacings and underlining of the separator
            padding-right: $breadcrumb-item-padding;
        }
    }

    + .breadcrumb-item:hover::before {
        text-decoration: underline;
    }
    // stylelint-disable-next-line no-duplicate-selectors
    + .breadcrumb-item:hover::before {
        text-decoration: none;
    }

    &.active {
        color: #929292;
    }

    &:last-child {
        a {
            color: $primary;
        }
    }
}


.carousel {
    $thumb-size: 3rem;

    .icon-prev,
    .icon-next {
        background-color: $white;
        font-size: 1.875em;
        // width and height here need to use rem units because the font size used here is 30px
        height: $thumb-size;
        padding-top: 0.24em;
        width: $thumb-size;

        &::before {
            color: $black;
            font-family: "FontAwesome";
        }
    }

    .icon-prev {
        &::before {
            content: "\f104";
        }
    }

    .icon-next {
        &::before {
            content: "\f105";
        }
    }

    .carousel-control-prev {
        justify-content: flex-start;
    }

    .carousel-control-next {
        justify-content: flex-end;
    }

    .carousel-control-next,
    .carousel-control-prev {
        width: $thumb-size;

        &:focus {
            outline: auto $black;
        }
    }
}
