$white: #fff;
$black: #000;
$blue: #002e6d;
$green: #4e885b;
// primary is replaced by css variable --skin-primary-color-1
$primary: #a17d23;
$red: #f64218;
$success: $green;
$danger: $red;
$light-blue: #7ed0ee;
$link-color: #81641c;
$link-hover: #a17d23;
$text-color-1: #a17d23;
$footer-backgorund: #f5f2e9;
$light-pink: #f8b7c3;

// Font variables
$font: "Proxima Nova","Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-base: "Playfair Display", Georgia, serif;
$font-family-sans-serif: "Playfair Display", Georgia, serif;

// Consolidated values
$grey1: #f9f9f9;
$grey2: #eee;
$grey3: #dedede;
$grey4: #999;
$grey5: #2f4f4f;
$grey6: #444;
$grey7: #222;
$grey8: #333;
$grey10: #555;
$grey20: #888;
$grey-transparent-1: rgba(0, 0, 0, 0.65);
$grey-transparent-2: rgba(0, 0, 0, 0.25);


//Brand Text colors
$text-white: #fff;
$text-primary: #000;
$text-secondary: #404040;
$text-tertiary: #818181;
$text-disabled: #818181;
$text-link-default: #000;
$text-input: #404040;

//General Brand colors
$surface-general-secondary: #f6f2e9;
$surface-general-white: #fff;
$input-placeholder-color: $text-primary;

//General Brand Buttons colors
$surface-button-primary: #fff;
$surface-button-hover: #fff;
$surface-button-active: #e3d8bd;
$surface-button-disabled: #81641c;
$surface-button-text: #e8e8e8;
$outline-button-default: #4c4c4c;

$surface-fields-button: #fff;
$surface-fields-button-disabled: #f8f8f8;
$surface-fields-light: #fcfcfc;

$border-divider-default: #a7a9ab;
$border-divider-light: #e8e8e8;
$border-divider-dark: #4c4c4c;

$border-button-default: #a17d23;
$border-button-hover: #002e6d;
$border-button-active: #a17d23;

//General background colors
$bg-secondary: #f3f3f3;
$bg-snow-white: #fef7f8;
$bg-solitude-blue: #e5eaf0;

$dark-gray: $grey6;
$darker-gray: $grey7;
$horizontal-rule-grey: #dedede;
$product-number-grey: $horizontal-rule-grey;
$horizontal-border-grey: $grey4;
$menu-link: $grey6;
$close-menu-bg: $grey2;
$hr-border-color: $horizontal-rule-grey;

$grid-breakpoints: (
    xs: 0,
    sm: 544px,
    md: 768px,
    lg: 1200px,
    xl: 1440px,
);

$container-max-widths: (
    md: 768px,
    lg: 1200px,
    xl: 1440px,
);

// Grid columns
// Set the number of columns and specify the width of the gutters.

$grid-gutter-width: 32px;
