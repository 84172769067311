@import "~base/account/account";
@import "../utilities/unit";
@import "../variables";
@import "../components/formFields";
@import "../utilities/mixins";
@import "../utilities/deleteCardButton";
@import "../product/detail";

.btn {
    &.btn-save {
        width: 100%;
    }

    &.delete-confirmation-btn {
        margin-left: rem-calc(8);
    }
}

.profile-back-to-account-link,
.back-to-account-link {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: rem-calc(46);
}

.card-footer > a {
    color: $white;
    text-decoration: none;
}

.account-preference {
    padding-bottom: rem-calc(32);


    .account-sub-preference {
        margin-left: rem-calc(16);

        @include media-breakpoint-up(lg) {
            margin-left: rem-calc(32);
        }
    }

    .account-preference-text {
        font-size: rem-calc(16);
        line-height: 1.5;
        padding-top: rem-calc(16);
    }

    .account-preference-text-here {
        text-decoration: underline;
    }

    .page-title {
        font-size: rem-calc(24);

        @include media-breakpoint-up(lg) {
            font-size: rem-calc(32);
        }
    }
}

.gift-registry-landing-page,
.login-form-nav {
    margin-bottom: rem-calc(32);

    .card-header {
        margin: 0 auto;
        padding: 16px 0 8px;
        width: 95%;
    }

    .btn-link {
        color: #404040;
        font-size: 12px;
        line-height: 2;
        text-decoration: underline;

        &:hover {
            color: #a17d23;
        }
    }

    .nav-tabs {
        align-items: center;
        border: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 0;
        padding: 0;

        @include media-breakpoint-up(md) {
            justify-content: center;
        }
    }

    .btn.active {
        @include btn-primary();

        border-color: transparent;
        border-radius: 0;
    }

    .btn:hover,
    .btn:focus {
        border-color: transparent;
        border-radius: 0;
    }

    .nav-link {
        border: 0;
        border-bottom: 1px solid  $border-color;
        margin: 0;
        text-decoration: none;
        text-transform: uppercase;
        width: auto;

        &.active {
            background-color: transparent;
            border-bottom: 2px solid $primary;
            color: $primary !important;
            font-weight: bold;
        }
    }

    .gift-registry-url {
        height: 90px;
    }
}

.card-link {
    color: $link-color !important;
    font-size: rem-calc(14);
    letter-spacing: 1px;
    padding: rem-calc(16 20);
    text-decoration: underline;

    &:hover {
        color: $blue !important;
    }
}

.login-oauth {
    a {
        text-decoration: none;
    }

    .social-icons {
        display: inline-block;
        height: rem-calc(24);
        margin-right: rem-calc(15);
        margin-top: rem-calc(-2);
        width: rem-calc(25);
    }
}

.login-form-nav {
    margin-bottom: 0;
}
