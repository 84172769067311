/* STYLE FOR CHECKBOX*/
@import "../utilities/unit";

.form-group.custom-checkbox,
.form-group .custom-checkbox {
    input[type="checkbox"] { /* stylelint-disable-line */
        appearance: none;
    }

    .custom-control-label {
        color: $black;
        cursor: pointer;
        font-size: rem-calc(16);
        font-weight: 400;
        line-height: 1.6;
    }

    &.required .form-control-label::before {
        color: $danger;
        content: "*";
    }

    input[type="checkbox"]:focus ~ .custom-control-label::before { /* stylelint-disable-line */
        box-shadow: none;
    }

    input[type="checkbox"]:checked ~ .custom-control-label::before { /* stylelint-disable-line */
        background-color: $blue;
        border-color: $blue;
    }

    input[type="checkbox"]:checked ~ .custom-control-label::after { /* stylelint-disable-line */
        background-image: url("../../images/icons/check-icon.svg");
        background-size: 16px;
    }

    .custom-control-label::before {
        border-color: $grey3;
    }
}

/* stylelint-disable */
.form-group {
    input.disabled {
        pointer-events: none;
    }
}

/* STYLE FOR RADIO*/
.custom-radio {
    min-height: rem-calc(30);

    input[type="radio"] {
        appearance: none;
    }

    .custom-control-label {
        color: $text-secondary;
        font-size: rem-calc(16);
        font-weight: 400;
    }

    .custom-control-label::before {
        background-color: $white;
        border-color: $grey3;
        box-shadow: rem-calc(0 4 4 0) #0000000a inset;
        height: rem-calc(18);
        width: rem-calc(18);

    }

    input[type="radio"]:checked ~ .custom-control-label::after,
    input[type="radio"].active ~ .custom-control-label::after {
        background-color: $blue;
        background-image: none;
        border-color: $blue;
        border-radius: 100%;
        height: rem-calc(14);
        left: rem-calc(-22);
        right: 0;
        top: rem-calc(6);
        width: rem-calc(14);

    }

    input[type="radio"]:checked ~ .custom-control-label::before {
        background-color: transparent;
        border-color: $blue;
    }

    .custom-control-label > span {
        line-height: 1.7;
        margin: rem-calc(0 0 0 8);
    }
}

.form-control {
    color: $black;

    &:focus {
        box-shadow: none;
    }

    &::placeholder {
        color: $black;
        font-size: 16px;
        font-weight: 400;
        line-height: 120%; /* 21.6px */
    }
}

select.form-control {
    appearance: none;
    background: url("../../images/icons/cheron-down.svg") no-repeat 98% center;
    text-transform: capitalize;

    &.radius {
        text-transform: none;
    }

    option[disabled=disabled],
    option[disabled]
     {
        color: $grey3;
    }
}

select.custom-select:focus,
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
    box-shadow: none;
}

.was-validated select.form-control:invalid,
select.form-control.is-invalid {
    background: url("../../images/icons/cheron-down.svg") no-repeat 98% center, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23f64218' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f64218' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
    background-position: 85%, 97%;

    @include media-breakpoint-up(lg) {
        background-position: 90%, 97%;
    }
}

.custom-checkbox .custom-control-label {
    line-height: 1.2;
    padding-left: rem-calc(8);

    @include media-breakpoint-up(lg) {
        line-height: 1.7;
    }

    &::before {
        border-radius: 0;
        box-shadow: none;
        height: rem-calc(18);
        width: rem-calc(18);
    }

    &::after {
        border-radius: 0;
        box-shadow: none;
        height: rem-calc(18);
        width: rem-calc(18);
    }
}

.form-control-label,
.form-group label {
    color: $black;
    font-size: rem-calc(14);
    font-weight: 500;
    line-height: 120%; /* 16.8px */
    margin-bottom: rem-calc(4);
}

.form-control.is-invalid + .invalid-feedback {
    font-weight: 500;
    line-height: 120%; /* 16.8px */
}


.card-number-wrapper {
    &::after {
        right: rem-calc(10);
        top: rem-calc(10);
    }
}

.custom-control {
    &.remember-me {
        margin: 0;
    }
}

.custom-select {
    &:focus {
        border-color: $primary;
    }
}

.btn-primary:focus,
.btn-primary.focus {
    border-color: #002e6d;
    background-color: #002e6d;
}

.form-control:focus {
    border-color: $primary !important;
    box-shadow: none !important;
}
