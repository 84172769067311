@import "~base/login";
@import "utilities/unit";
@import "variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "components/formFields";
@import "utilities/unit";
@import "account/account";

.login-page {
    margin-bottom: rem-calc(20);

    .card {
        border-color: $border-divider-light;
    }
}

.communication-preferences-label {
    font-weight: bold;
}

.privacy-policy {
    margin-top: rem-calc(12);
}
