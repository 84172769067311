@import "../variables";
@import "~plugin_instorepickup/product/pdpInstoreInventory";
@import "../components/formFields";
@import "../utilities/unit";
@import "../bootstrapOverrides";
@import "~plugin_giftregistry/giftRegistry/addProductToRegistry";
@import "../utilities/swatch";

.product-detail,
.quick-view-dialog,
.product-set-detail,
.choose-bonus-product-dialog {
    .carousel-control-prev,
    .carousel-control-next {
        bottom: auto;
        text-decoration: none;
        top: 50%;
        transform: translateY(-50%);
    }

    .product-breadcrumb {
        border: 0;
    }

    // stylelint-disable-next-line
    .wishlistTile {
        margin: 0;
        padding: 0;
        position: relative;
        right: 0;

        .icon-heart {
            height: rem-calc(22);
            width: rem-calc(24);
        }
    }

    .price {
        .starting,
        .range,
        .sales {
            font-size: rem-calc(24);
            font-weight: 700;
        }
    }

    .product-brand {
        color: $primary !important;
        font-size: rem-calc(20);
        font-weight: normal;
        text-decoration: none;

        &:hover {
            color: $black;
            text-decoration: underline;
        }
    }

    .afterpay-widget {
        padding: rem-calc(0 16);
    }

    .availability,
    .availability-msg,
    .attribute {
        color: $black;
        font-size: rem-calc(14);
        text-transform: capitalize;

        label {
            color: $black;
            font-size: rem-calc(14);
        }
    }

    .pdp-attributes {
        .color-attribute {
            @include swatch-square();

            margin-bottom: rem-calc(5);
            margin-right: 0;

            img { /* stylelint-disable */
                background-color: transparent;
                border: 0;
                border-radius: 0;
                height: 100%;
                width: 100%;

                &::after {
                    content: none !important;
                }
            }

            .unselectable {
                opacity: 0.4;
            }
        }
    }

    .social-container {
        .social-icons {
            border-bottom: rem-calc(1) solid $grey3;
            border-top: rem-calc(1) solid $grey3;
            list-style: none;
            margin: rem-calc(16 0 0);
            padding: rem-calc(16 0 0);

            li {
                display: inline-block;
                padding-bottom: rem-calc(16);
            }

            span {
                font-size: 13px;
                padding-left: 10px;
            }
        }
    }

    .gift-register-button,
    .click-and-collect-button {
        border-bottom: rem-calc(1) solid $grey3;

        button {
            color: $black;
            font-weight: 700;
            letter-spacing: 1px;
            padding: rem-calc(16 0 24);
        }

        span {
            border-bottom: rem-calc(1) solid $black;
            color: $black;
            display: inline-block;
            font-size: rem-calc(14);
            margin-left: rem-calc(16);
            padding-top: rem-calc(6);
            text-transform: uppercase;

            &:hover {
                border-bottom: rem-calc(1) solid $primary;
                color: $primary;
            }
        }
    }

    .key-sales-section {
        p {
            font-size: rem-calc(13);
        }
    }

    .click-and-collect-button {
        border-bottom: 0;

        button {
            padding: rem-calc(16 0 0);
        }
    }

    .carousel-item.active {
        display: flex;
        justify-content: center;
    }

    .product-details-section,
    .product-image-section {
        padding: rem-calc(16) 0;

        @include media-breakpoint-up(lg) {
            padding: 0 rem-calc(16);
        }
    }

    .product-name {
        padding: rem-calc(0 0 16);
    }

    .add-to-wish-icon {
        @include media-breakpoint-down(md) {
            position: absolute;
            right: 0;
            top: 0;
            z-index: 11;
        }
    }

    .carousel-indicators {
        align-items: center;
        margin: 0;
        width: 100%;

        li {
            border-radius: 99%;
            height: rem-calc(10);
            opacity: 1;
            width: rem-calc(10);

            &.active {
                background-color: $primary;
                height: rem-calc(12);
                width: rem-calc(12);
            }
        }
    }

    .carousel {
        .icon-prev,
        .icon-next {
            box-shadow: -2px 1px 20px 0px rgba(0, 0, 0, 0.5);
        }

        .icon-next {
            &::before {
                background-image: url("../../images/arrow-slider-right.svg");
                content: "";
                display: inline-block;
                height: rem-calc(22);
                width: rem-calc(22);
            }
        }

        .icon-prev {
            &::before {
                background-image: url("../../images/arrow-slider-left.svg");
                content: "";
                display: inline-block;
                height: rem-calc(22);
                width: rem-calc(22);
            }
        }
    }

    .pdp-attributes {
        .color {
            display: none;

            &.color-label {
                display: block;
            }
        }
    }

    .promotions-wrapper {
        border: rem-calc(2) solid $primary;
        margin-bottom: rem-calc(16);
        padding: rem-calc(16);

        .title {
            color: $primary;
            font-family: "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
            font-weight: 700;
            font-size: rem-calc(14);
            text-transform: uppercase;
        }

        .content {
            color: $black;
            font-family: "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
            font-weight: 400;
            font-size: rem-calc(14);
            margin: 0;
        }

        img {
            @include media-breakpoint-down(lg) {
                width: 100%;
            }
        }
    }

    .promotions {
        padding-top: rem-calc(16) !important;
    }
}

.xzoom-source,
.xzoom-preview,
.xzoom-lens {
    img {
        display: block;
        max-height: none;
        max-width: none;
        transition: none;
        transition: none;
        transition: none;
        transition: none;
    }
}

.xzoom-container {
    display: inline-block;
}

.xzoom-source,
.xzoom-hidden {
    clear: both;
    display: block;
    float: none;
    position: static;
}

.xzoom-source {
    @include media-breakpoint-down(md) {
        display: none;
    }
}

.xzoom-hidden {
    overflow: hidden;
}

/* Preview */
.xzoom-preview {
    background: $grey5;
    border: 1px solid $grey20;
    box-shadow: -0 -0 10px rgba(0, 0, 0, 0.5);
}

/* Lens */
.xzoom-lens {
    border: 1px solid $grey10;
    box-shadow: -0 -0 10px rgba(0, 0, 0, 0.5);
    cursor: crosshair;
}

/* Loading */
.xzoom-loading {
    background: url("../example/images/xloading.gif");
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 100%;
    height: 48px;
    opacity: 0.7;
    width: 48px;
}

/* Caption */
.xzoom-caption {
    background: $black;
    bottom: -43px;
    left: 0;
    position: absolute;
    text-align: left;
    width: 100%;
}

.xzoom-caption span {
    color: $white;
    display: block;
    font-size: 0.75em;
    font-weight: bold;
    padding: 10px;
}

.xzoom-close {
    background: $white;
    color: $black;
    font-size: 45px;
    height: 30px;
    line-height: 25px;
    position: absolute;
    right: 2px;
    text-align: center;
    top: 1px;
    width: 30px;
}


.afterpay-paragraph {
    color: $text-secondary !important;
    font-size: rem-calc(14) !important;
}

.description-section {
    border-bottom: rem-calc(1) solid $grey3;

    &:last-child {
        border-bottom: 0;
    }
}

.description-wrapper {
    margin-top: rem-calc(32);

    @include media-breakpoint-down(lg) {
        margin-top: rem-calc(16);
    }

    .description-and-detail {
        margin: rem-calc(32 0);

        @include media-breakpoint-down(lg) {
            margin: rem-calc(16 0);
            padding: 0;
        }

        .title {
            font-size: rem-calc(14);
            font-weight: 600;
            line-height: rem-calc(24);
            margin-bottom: 0;
            padding: rem-calc(10 0 0);
            text-transform: uppercase;

            @include media-breakpoint-up(lg) {
                padding: rem-calc(10 0);
            }
        }

        > div:last-child {
            margin-bottom: 0;
        }

        .description {
            margin-bottom: 0;
        }

        .content {
            color: $black;
            font-size: rem-calc(14);
            font-weight: 400;
            line-height: rem-calc(20);
            margin: 0;
            padding: 0;
        }
    }

    .product-number {
        color: $black;
        font-size: rem-calc(14);
    }
}

.product-set  {
    padding: rem-calc(0 24);

    .product-name {
        border-bottom: 1px solid $grey3;
    }
}